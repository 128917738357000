import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Arizona",
  "subtitle": "May 2018",
  "category": "USA"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "general"
    }}>{`General`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://store.usgs.gov/"
        }}>{`National Park Pass`}</a>{` - 1 pass per car`}</li>
    </ul>
    <h2 {...{
      "id": "antelope-canyon"
    }}>{`Antelope Canyon`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/nmP5qc1duBCXFbDU7"
        }}>{`Upper Antelope Canyon`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.antelopecanyon.com/"
            }}>{`Antelope Canyon Tours`}</a>{` - Sightseer's Tour`}</li>
          <li parentName="ul">{`Very crowded but it's guided walking tours only`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://horseshoebend.com/"
        }}>{`Horseshoe Bend`}</a>{` - 20 minute uphill climb but wasn't good for sunset`}</li>
    </ul>
    <h2 {...{
      "id": "grand-canyon"
    }}>{`Grand Canyon`}</h2>
    <p>{`Included in the National Park Pass`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.nps.gov/grca/planyourvisit/desert-view-drive.htm"
        }}>{`Desert view drive`}</a>{` was quite scenic`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      